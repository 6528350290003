import { toWSDateString } from "@wingspanhq/fe-component-library";
import {
  ClientWorkFlowStatus,
  IClientInvoice,
  ICollaboratorSchema,
  ICollaboratorV2,
  IInvoice,
  IInvoicePayoutDestination,
  IInvoiceTemplate,
  IMemberClient,
  InvoicePayoutDestinationType,
  InvoiceStatus,
  IPayableSchema,
  MemberClientStatus,
  MemberClientTaxStatus,
  MemberWorkFlowStatus,
  PayoutPreferences
} from "@wingspanhq/payments/dist/interfaces";
import {
  IMember,
  UserAccountType
} from "@wingspanhq/users/dist/lib/interfaces";
import { calculateLineItemsTotal } from "../../Invoices/utils";
import { IInvoiceRow } from "../../modules/Invoicing/service";
import getCurrentFilingYear from "../../utils/getCurrentFilingYear";
import { getIsDomesticMember, getRedactedUserName } from "../users/selectors";

export const getInvoices = (invoices: IInvoice[]) =>
  invoices.filter(invoice =>
    invoice.status === InvoiceStatus.Pending
      ? invoice.labels?.creationSource !== "personalPaylink"
      : true
  );

export const getInvoiceTemplates = (invoiceTemplates: IInvoiceTemplate[]) =>
  invoiceTemplates.filter(
    invoiceTemplate => !invoiceTemplate.labels?.parentInvoiceTemplateId
  );

export const getClients = (memberClients: IMemberClient[]) =>
  memberClients
    .filter(
      memberClient =>
        memberClient.status === MemberClientStatus.Active ||
        memberClient.status === MemberClientStatus.Pending
    )
    .filter(memberClient => memberClient.name);

export const getClientsV2 = (memberClients: ICollaboratorV2[]) =>
  memberClients
    .filter(
      memberClient =>
        memberClient.status === MemberClientStatus.Active ||
        memberClient.status === MemberClientStatus.Pending
    )
    .sort((a, b) =>
      (getCollaboratorName(a) || "")
        .toLocaleLowerCase()
        .localeCompare((getCollaboratorName(b) || "").toLocaleLowerCase())
    )
    .sort((a, b) =>
      a.member?.user?.email && b.member?.user?.email
        ? a.member.user.email
            .split("@")[1]
            .toLocaleLowerCase()
            .localeCompare(
              b.member.user.email.split("@")[1].toLocaleLowerCase()
            )
        : 0
    );

export const getVisibleCollaborators = (collaborators: ICollaboratorSchema[]) =>
  getSortedCollaborators(
    collaborators.filter(
      collaborator =>
        (collaborator.status === MemberClientStatus.Active ||
          collaborator.status === MemberClientStatus.Pending) &&
        collaborator.member && // TODO: Remove this hack once we can guarantee these exist
        collaborator.member.user.email !== "test-member+invoices@wingspan.test"
    )
  );

export const getSortedCollaborators = (collaborators: ICollaboratorSchema[]) =>
  collaborators
    .sort((a, b) =>
      (getCollaboratorName(a) || "")
        .toLocaleLowerCase()
        .localeCompare((getCollaboratorName(b) || "").toLocaleLowerCase())
    )
    .sort((a, b) =>
      a.member?.user?.email && b.member?.user?.email
        ? a.member.user.email
            .split("@")[1]
            ?.toLocaleLowerCase()
            ?.localeCompare(
              b.member.user.email.split("@")[1]?.toLocaleLowerCase()
            )
        : 0
    );

export const getVisibleCollaboratorsV2 = (collaborators: ICollaboratorV2[]) =>
  getSortedCollaboratorsV2(
    collaborators.filter(
      collaborator =>
        (collaborator.status === MemberClientStatus.Active ||
          collaborator.status === MemberClientStatus.Pending) &&
        collaborator.member &&
        collaborator.member?.user.email !== "test-member+invoices@wingspan.test"
    )
  );
export const getSortedCollaboratorsV2 = (collaborators: ICollaboratorV2[]) =>
  collaborators
    .sort((a, b) =>
      (getCollaboratorName(a) || "")
        .toLocaleLowerCase()
        .localeCompare((getCollaboratorName(b) || "").toLocaleLowerCase())
    )
    .sort((a, b) =>
      a.member?.user?.email && b.member?.user?.email
        ? a.member.user.email
            .split("@")[1]
            .toLocaleLowerCase()
            .localeCompare(
              b.member.user.email.split("@")[1].toLocaleLowerCase()
            )
        : 0
    );

export const filterDuplicateCollaborators = (
  collaborators: ICollaboratorSchema[]
) => {
  const duplicateCollaboratorsMap: {
    [key: string]: ICollaboratorSchema[];
  } = {};
  let filteredCollaborators: ICollaboratorSchema[] = [];
  collaborators.forEach(collaborator => {
    const key = `${collaborator.memberId}-${collaborator.clientId}`;
    if (duplicateCollaboratorsMap[key]) {
      const collaborators = duplicateCollaboratorsMap[key] ?? [];
      duplicateCollaboratorsMap[key] = [...collaborators, collaborator];
    } else {
      duplicateCollaboratorsMap[key] = [collaborator];
    }
  });
  Object.values(duplicateCollaboratorsMap).forEach(collaboratorsList => {
    if (collaboratorsList.length === 1) {
      filteredCollaborators.push(collaboratorsList[0]);
    } else {
      // handle duplicate collaborators
      const groupedCollaborator = collaboratorsList.find(
        collaborator => collaborator.collaboratorGroupIds?.length
      );
      if (groupedCollaborator) {
        filteredCollaborators.push(groupedCollaborator);
      } else {
        filteredCollaborators.push(collaboratorsList[0]);
      }
    }
  });
  return filteredCollaborators;
};

export const getVisibleCollaboratorsSortByCreatedAt = (
  collaborators: ICollaboratorSchema[]
) =>
  collaborators
    .filter(
      collaborator =>
        (collaborator.status === MemberClientStatus.Active ||
          collaborator.status === MemberClientStatus.Pending) &&
        collaborator.member?.user?.email !==
          "test-member+invoices@wingspan.test"
    )
    .sort(
      (a, b) => (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0)
    );

export const getMemberClientByEmail = (
  memberClients: IMemberClient[],
  email: string
) => memberClients.find(memberClient => memberClient.emailTo === email);

export const getMemberClient = (
  memberClients: IMemberClient[],
  memberClientId: string
) =>
  memberClients.find(
    memberClient => memberClient.memberClientId === memberClientId
  );

export const getCollaborator = (
  collaborators: ICollaboratorSchema[],
  collaboratorId: string
) =>
  collaborators.find(
    collaborator => collaborator.collaboratorId === collaboratorId
  );

export const getInvoice = (invoices: IInvoice[], invoiceId: string) =>
  invoices.find(invoice => invoice.invoiceId === invoiceId);

export const getInvoiceTemplate = (
  invoiceTemplates: IInvoiceTemplate[],
  invoiceTemplateId: string
) =>
  invoiceTemplates.find(
    invoiceTemplate => invoiceTemplate.invoiceTemplateId === invoiceTemplateId
  );

export const getClientName = (memberClient: IMemberClient) =>
  memberClient.name ||
  (memberClient.client?.user?.profile as any)?.preferredName;

export const getClientCompany = (memberClient: IMemberClient) =>
  memberClient.company || memberClient.client?.profile?.company?.name;

export const getClientEmail = (memberClient: IMemberClient) =>
  memberClient.client?.user?.email as string;

export const getCollaboratorName = (
  collaborator: ICollaboratorSchema | ICollaboratorV2
) =>
  (collaborator.member?.user &&
    getRedactedUserName(collaborator.member.user)) ||
  getCollaboratorCompany(collaborator) ||
  getCollaboratorEmail(collaborator);

export const getCollaboratorCompany = (
  collaborator: ICollaboratorSchema | ICollaboratorV2
) =>
  collaborator.formW9Data?.legalBusinessName ||
  collaborator.member?.profile?.company?.legalBusinessName ||
  collaborator.member?.profile?.company?.name;

export const getCollaboratorEmail = (
  collaborator: ICollaboratorSchema | ICollaboratorV2
) => collaborator.member?.user?.email as string;

export const getCollaboratorPhone = (
  collaborator: ICollaboratorSchema | ICollaboratorV2
) => collaborator.member?.user?.phone?.number as string;

export const getCurrentYearForm1099Balance = (
  collaborator: ICollaboratorSchema | IMemberClient | ICollaboratorV2,
  year?: keyof ICollaboratorSchema["form1099Balances"]
) => collaborator.form1099Balances?.[year ?? getCurrentFilingYear()];

export const getIsInvoiceCreator = (
  invoice: IInvoice | IInvoiceRow,
  userId: string
) => {
  const ownerIds = invoice.userRoles?.ownerIds ?? [];
  return ownerIds.includes(userId);
};

export const getIsPayableCreator = (
  payable: IPayableSchema,
  userId: string
) => {
  const ownerIds = payable.userRoles?.ownerIds ?? [];
  return ownerIds.includes(userId);
};

export const getHasInvoiceEditAccess = (
  invoice: IInvoice | IInvoiceRow,
  userId: string
) => {
  const ownerIds = invoice.userRoles?.ownerIds ?? [];
  return ownerIds.length > 0 ? ownerIds.includes(userId) : true;
};

export const getHasPayableEditAccess = (
  payable: IPayableSchema,
  userId: string
) => {
  const ownerIds = payable.userRoles?.ownerIds ?? [];
  return ownerIds.length > 0 ? ownerIds.includes(userId) : true;
};

export const getPayrollRunTitle = (payrollRun: IInvoice) =>
  payrollRun.events?.paidAt
    ? toWSDateString(payrollRun.events.paidAt, "monthDayYear")
    : toWSDateString(payrollRun.dueDate, "monthDayYear");

export const getClientInvoiceMemberName = (clientInvoice: IClientInvoice) =>
  (clientInvoice.memberClient?.member?.user?.profile as any)?.preferredName ||
  clientInvoice.memberName ||
  clientInvoice.memberClient?.client?.user?.email;

export const getEligibleCollaboratorsFor1099Filing = (
  collaborators: ICollaboratorSchema[]
): ICollaboratorSchema[] => {
  const prevYear = new Date().getFullYear() - 1;
  return collaborators.filter(collaborator => {
    const prevYearForm1099Balances =
      collaborator.form1099Balances?.[
        prevYear as keyof ICollaboratorSchema["form1099Balances"]
      ];
    return (
      prevYearForm1099Balances?.platformIncome !== undefined ||
      prevYearForm1099Balances?.platformIncome === 0
    );
  });
};

export const getMissingInformationCollaborators = (
  collaborators: ICollaboratorSchema[]
): ICollaboratorSchema[] => {
  const prevYear = new Date().getFullYear() - 1;
  return collaborators.filter(collaborator => {
    const prevYearForm1099Balances =
      collaborator.form1099Balances?.[
        prevYear as keyof ICollaboratorSchema["form1099Balances"]
      ];
    const platformIncome = prevYearForm1099Balances?.platformIncome || 0;
    const adjustmentAmount = prevYearForm1099Balances?.adjustments || 0;
    const totalAmount = platformIncome + adjustmentAmount;
    return (
      collaborator.taxStatus === MemberClientTaxStatus.Incomplete &&
      totalAmount >= 600
    );
  });
};

export const getPayablesByUploadBatchId = (
  payables: IPayableSchema[],
  uploadBatchId: string
) =>
  payables.filter(
    payable =>
      payable.lineItems?.filter(
        lineItem => lineItem.labels?.uploadBatchId === uploadBatchId
      ).length > 0
  );

export const getPayables = (payables: IPayableSchema[]) =>
  payables.filter(payable =>
    payable.client?.workflowStatus === ClientWorkFlowStatus.Declined
      ? payable.events?.clientDeclinedAt &&
        payable.events?.memberResubmittedAt &&
        payable.events.memberResubmittedAt > payable.events.clientDeclinedAt
      : true
  );

export const getMemberAcceptedPayables = (payables: IPayableSchema[]) =>
  payables.filter(
    payable => payable.member?.workflowStatus === MemberWorkFlowStatus.Accepted
  );

export const getPayablesTotalAmount = (payables: IPayableSchema[]) =>
  payables.reduce((total, payable) => {
    return total + calculateLineItemsTotal(payable.lineItems || []);
  }, 0);

export const getInvoicePayoutDestinationDescription = (
  payoutDestination: IInvoicePayoutDestination
) => {
  if (
    payoutDestination.destinationType === InvoicePayoutDestinationType.Account
  ) {
    return `${payoutDestination.description || ""}${
      payoutDestination.last4 ? ` (${payoutDestination.last4})` : ""
    }`;
  }

  if (payoutDestination.destinationType === InvoicePayoutDestinationType.Card) {
    return (
      (payoutDestination.brand
        ? `${payoutDestination.brand} Debit`
        : payoutDestination.description) +
      (payoutDestination.last4 ? ` Debit (${payoutDestination.last4})` : "")
    );
  }

  return payoutDestination.description || "";
};

export const getIsInstantPayoutAllowed = (invoice: IInvoice, member: IMember) =>
  invoice.status === InvoiceStatus.Paid &&
  invoice.member?.payoutPreferences !== PayoutPreferences.Instant &&
  !!invoice.amountDetails &&
  !invoice.events?.depositedAt &&
  getIsDomesticMember(member) &&
  member.user?.settings?.userAccountType !== UserAccountType.enterprise;

export const getIsPayableDisputed = (payable: IPayableSchema) =>
  payable.member?.workflowStatus === MemberWorkFlowStatus.Disputed &&
  (!payable.events?.clientResolvedDisputeAt ||
    (payable.events?.memberDisputedAt &&
      payable.events.memberDisputedAt >
        payable.events.clientResolvedDisputeAt));

export const getIsPayableResubmitted = (payable: IPayableSchema) =>
  payable.client?.workflowStatus === ClientWorkFlowStatus.Declined &&
  payable.events?.clientDeclinedAt &&
  payable.events?.memberResubmittedAt &&
  payable.events.clientDeclinedAt < payable.events.memberResubmittedAt;

export const getIsPayableAccepted = (payable: IPayableSchema) =>
  payable.member?.workflowStatus === MemberWorkFlowStatus.Accepted &&
  !!payable.events?.memberAcceptedAt;

export const getIsInvoiceDisputed = (invoice: IInvoice | IInvoiceRow) =>
  invoice.member?.workflowStatus === MemberWorkFlowStatus.Disputed &&
  (!invoice.events?.clientResolvedDisputeAt ||
    (invoice.events?.memberDisputedAt &&
      invoice.events.memberDisputedAt >
        invoice.events.clientResolvedDisputeAt));

export const getIsInvoiceResubmitted = (invoice: IInvoice | IInvoiceRow) =>
  invoice.member?.workflowStatus === MemberWorkFlowStatus.Disputed &&
  invoice.events?.memberDisputedAt &&
  invoice.events?.clientResolvedDisputeAt &&
  invoice.events.memberDisputedAt < invoice.events.clientResolvedDisputeAt;

export const getIsInvoiceRejected = (invoice: IInvoice | IInvoiceRow) =>
  invoice.client?.workflowStatus === ClientWorkFlowStatus.Declined &&
  (!invoice.events?.memberResubmittedAt ||
    (invoice.events?.clientDeclinedAt &&
      invoice.events.clientDeclinedAt > invoice.events.memberResubmittedAt));

export const getIsInvoiceAccepted = (invoice: IInvoice | IInvoiceRow) =>
  invoice.member?.workflowStatus === MemberWorkFlowStatus.Accepted &&
  !!invoice.events?.memberAcceptedAt;
