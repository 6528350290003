import {
  useWSSnackbar,
  WSDateRangeSelectOptions
} from "@wingspanhq/fe-component-library";
import {
  IInvoice,
  IInvoiceLineItem,
  IInvoiceTemplate,
  IMemberClient,
  ScheduleStatus
} from "@wingspanhq/payments/dist/interfaces";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import min from "lodash/min";
import queryString from "qs";
import { useUserId } from "../../query/hooks/helpers";
import { useMemberClientsQuery } from "../../query/payments/queries";
import { useMemberProfile } from "../../query/users/queries";
import { getMemberCompanyName } from "../../query/users/selectors";
import { calculateDayDifference } from "../../utils/dates";
import { downloadFile } from "../../utils/files";

export const useGetMemberClient = () => {
  const memberClientsQuery = useMemberClientsQuery();
  const memberClients = memberClientsQuery.data || [];

  return (memberClientId: string): IMemberClient | undefined => {
    return memberClients.find(
      memberClient => memberClient.memberClientId === memberClientId
    );
  };
};

export const getInvoiceTemplateDueInDays = (
  invoiceTemplate: IInvoiceTemplate
) => {
  const sendDate =
    min([
      invoiceTemplate.scheduleDates?.[0]?.date,
      invoiceTemplate.frequency?.startDate
    ]) || new Date();
  const dueDate = invoiceTemplate.invoiceData.dueDate;
  return calculateDayDifference(dueDate, sendDate);
};

export const calculateInvoiceTempalteDueDate = (
  invoiceTemplate: IInvoiceTemplate
) => {
  const dueInDays = getInvoiceTemplateDueInDays(invoiceTemplate);

  const nextInvoiceDate =
    invoiceTemplate.scheduleDates?.[0] &&
    invoiceTemplate.scheduleDates[0].status === ScheduleStatus.Pending
      ? invoiceTemplate.scheduleDates[0].date
      : invoiceTemplate.nextInvoiceDate;

  return moment(nextInvoiceDate).add(dueInDays, "days").toDate();
};

export const calculateLineItemRegularPrice = (lineItem: IInvoiceLineItem) =>
  lineItem.unit ? lineItem.costPerUnit * lineItem.quantity : lineItem.totalCost;

export const calculateLineItemDiscount = (lineItem: IInvoiceLineItem) => {
  if (lineItem.discount) {
    if (lineItem.discount.amount) {
      return lineItem.discount.amount;
    }

    if (lineItem.discount.percentage) {
      const regularPrice = calculateLineItemRegularPrice(lineItem);
      return (regularPrice * lineItem.discount.percentage) / 100;
    }
  }
};

export const calculateLineItemTotal = (
  lineItem: IInvoiceLineItem,
  options?: { applyDiscount?: boolean }
) => {
  const regularPrice = calculateLineItemRegularPrice(lineItem);

  if (options?.applyDiscount) {
    const discount = calculateLineItemDiscount(lineItem);
    if (discount) {
      return regularPrice - discount;
    }
  }
  return regularPrice;
};

export const calculateLineItemsTotal = (lineItems: IInvoiceLineItem[]) =>
  lineItems.reduce((total, lineItem) => {
    return total + calculateLineItemTotal(lineItem, { applyDiscount: true });
  }, 0);

export const timeToQuantity = (hours: number, minutes: number): number =>
  hours + Math.round((minutes / 60) * 100) / 100;

export const quantityToTime = (quantity: number): [number, number] => {
  const hours = Math.trunc(quantity);
  const minutes = Math.round(quantity * 60) % 60;
  return [hours, minutes];
};

export const calculateInvoicesTotal = (invoices: IInvoice[]) =>
  invoices.reduce(
    (total, invoice) => total + calculateLineItemsTotal(invoice.lineItems),
    0
  );

export const calculateInvoicesTotalMemberGross = (invoices: IInvoice[]) =>
  invoices.reduce(
    (total, invoice) =>
      total + Math.abs(invoice.amountDetails?.memberGross || 0),
    0
  );

export const getIsFirstInvoiceSent = (invoiceTemplate: IInvoiceTemplate) =>
  !invoiceTemplate.scheduleDates ||
  (invoiceTemplate.scheduleDates &&
    invoiceTemplate.scheduleDates[0] &&
    invoiceTemplate.scheduleDates[0].status === ScheduleStatus.Completed);

export const getNextInvoiceSendDate = (invoiceTemplate: IInvoiceTemplate) => {
  if (invoiceTemplate.isSchedulingOnly) {
    const closestScheduleDate = (invoiceTemplate.scheduleDates || []).find(
      scheduleDate =>
        (scheduleDate.status === ScheduleStatus.Pending ||
          scheduleDate.status === ScheduleStatus.Modified) &&
        scheduleDate.date > new Date()
    );

    if (closestScheduleDate) {
      return closestScheduleDate.date;
    }
  } else {
    return invoiceTemplate.scheduleDates &&
      invoiceTemplate.scheduleDates[0] &&
      invoiceTemplate.scheduleDates[0].status === ScheduleStatus.Pending
      ? invoiceTemplate.scheduleDates[0].date
      : invoiceTemplate.nextInvoiceDate;
  }
};

export const INVOICE_ACCOUNT_NAME = "Invoice Payouts Account";

export const useDownloadPdf = () => {
  const { openSnackbar } = useWSSnackbar();

  return (url: string, filename?: string) => {
    downloadFile(url, filename);
    openSnackbar({
      message: "PDF downloaded"
    });
  };
};

export const useDefaultInvoiceSubject = () => {
  const userId = useUserId();
  const memberQuery = useMemberProfile(userId);

  return `Payment requested - New invoice from ${
    memberQuery.data ? getMemberCompanyName(memberQuery.data) : "TDB"
  }`;
};

export enum DateRangeFilter {
  All = "All",
  PastWeek = "PastWeek",
  PastMonth = "PastMonth",
  PastYear = "PastYear",
  Custom = "Custom"
}

export const getDateRangeFilterRanges = (
  range: DateRangeFilter,
  customRange?: Date[]
): [Date, Date] | null => {
  switch (range) {
    case DateRangeFilter.Custom:
      if (customRange) {
        return [customRange[0], customRange[1]];
      } else {
        return null;
      }

    case DateRangeFilter.PastWeek:
      return WSDateRangeSelectOptions.PastWeek.range;

    case DateRangeFilter.PastMonth:
      return WSDateRangeSelectOptions.PastMonth.range;

    case DateRangeFilter.PastYear:
      return WSDateRangeSelectOptions.PastMonth.range;

    default:
      return null;
  }
};

export const getAutopayLink = (params: {
  memberClientId?: string;
  payeeId?: string;
  companyName: string;
  tag: string;
  email: string;
}) => {
  const searchParams = {
    companyName: params.companyName,
    mc: params.memberClientId,
    p: params.payeeId,
    email: params.email
  };
  // /autopay/<tag>?growth_name=belay&companyName=BELAY&mc=<memberClientId>&email=<email>&enabled_payment_methods=credit,ach,manual
  return `${window.location.host}/autopay/${params.tag}?${queryString.stringify(
    searchParams
  )}`;
};
